<template>
  <div v-if="signinAsAdmin || signinAsAgent || signinWLAdmin" class="demo-spacing-0 mb-2">
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span v-if="!flag" class="alert-text">
          <strong>
            Alert!
          </strong>
          You are logged in <strong v-if="signinWLAdmin">to {{ auth.user.whitelabel.name }} Whitelabel admin panel. </strong><span v-else>as {{ auth.user.firstName }}.</span>
          <strong class="switch-text text-warning" @click="switchToSuperAdmin">
            Switch back to 
            <span v-if="signinWLAdmin">Beontrips.</span>
            <span v-else>your account.</span>
          </strong>
        </span>
      </div>
    </b-alert>
  </div>
</template>

<style scoped>
.switch-text {
    cursor: pointer;
    text-decoration: underline;
}
</style>

<script>
import { BAlert } from 'bootstrap-vue'
import { mapState } from 'vuex'
import UserAdminService from '@/services/user-admin/userAdmin.service'
import errorResponseHandler from '@/services/errorHandler'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { resConditionCheck } from '@/helpers/constant'

export default {
  components: {
    BAlert,
  },
  data() {
    return {
      signinAsAdmin: '',
      signinAsAgent: '',
      signinWLAdmin: '',
      flag: false
    }
  },

  computed: {
    ...mapState(['auth'])
  },

  mounted() {
    this.signinAsAdmin = localStorage.getItem('signinAsAdmin')
    this.signinAsAgent = localStorage.getItem('signinAsAgent')
    this.signinWLAdmin = localStorage.getItem('signinWLAdmin')
  },

  methods: {
    switchToSuperAdmin() {
      const superAdminId = localStorage.getItem('superAdmin_userId')
      const wlCode = (localStorage.getItem('signinWLAdmin')) ? 1 : this.auth.user.whitelabel.id
      UserAdminService.signInAsAnotherUser({ user_id: superAdminId }, wlCode).then(res => {
        if (resConditionCheck(res.status) && res.data.user_details) {
          this.flag = true
          localStorage.removeItem('signinAsAdmin')
          localStorage.removeItem('signinAsAgent')
          localStorage.removeItem('signinWLAdmin')
          localStorage.removeItem('whitelabel_code')
          window.location.href = '/'
          this.$store.commit('auth/SET_TOKEN', res.data.user_details.token)
          this.$store.commit('auth/SET_USER', res.data.user_details)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      }).catch(error => {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      })
    }
  }
}
</script>
