<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in menuList"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import { mapState } from 'vuex'
import { RoleEnum } from '@/helpers/constant'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(['auth']),
    menuList() {
      const menus = this.items
      const rights = this.auth.user.rights?.module
      const changedMenuArray = (this.auth.user.roleId !== RoleEnum.SuperAdmin || this.auth.user.wlCode !== 1) ? [] : menus
      if (this.auth.user.roleId !== RoleEnum.SuperAdmin || this.auth.user.wlCode !== 1) {
        for (let i = 0; i < JSON.parse(JSON.stringify(menus)).length; i += 1) {
          const idx = rights.findIndex(e => (e.main_name ? e.main_name : e.name === menus[i].title))

          if (idx > -1 && (rights[idx].view || (menus[i].children?.length && (rights[idx].Add || rights[idx].view)))) {
            changedMenuArray.push(menus[i])
            if (menus[i].children && menus[i].children.length) {
              /* eslint-disable */
              const childArr = menus[i].children.filter(e => {
                const idxOfChild = rights.findIndex(z => z.name === e.title)
                if ((idxOfChild > -1 && rights[idxOfChild].view)) {
                  return e
                } else if (e.title === 'Add' || e.title === 'List') {
                  const idxOfListAddChild = rights.findIndex(zz => zz.name === menus[i].title)
                  if (idxOfListAddChild > -1) {
                    if (e.title === 'Add') {
                      if (rights[idxOfListAddChild].Add) {
                        return e;
                      }
                    } else if (e.title === 'List') {
                      if (rights[idxOfListAddChild].view) {
                        return e;
                      }
                    }
                  }
                }
              })
              /* eslint-disable */
              menus[i].children = childArr && childArr.length ? childArr : []
            }
          }
        }
      }
      return changedMenuArray
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },

}
</script>
