import axios from 'axios';

function getAll(payload) {
  return axios.get('admin/list/all', {
    params: payload
  })
}

function deleteAdmin(id) {
  return axios.delete(`admin/${id}`)
}

function createAdmin(userData) {
  return axios.post('admin', userData)
}

function updateAdmin(id, editData) {
  return axios.put(`admin/edit/${id}`, editData)
}

function getAdminById(id) {
  return axios.get(`admin/${id}`)
}

function getAdminFilter(id) {
  return axios.post(`admin/list/filters/${id}`)
}

function signInAsAnotherUser(id, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.post('admin/signin-as-admin', id)
  axios.defaults.headers.wl_code = wlCode
  return response
}

export default {
  getAll,
  deleteAdmin,
  createAdmin,
  updateAdmin,
  getAdminById,
  getAdminFilter,
  signInAsAnotherUser
}
