import axios from 'axios'

function getModuleListing() {
  return axios.get('module')
}

function editModule(id, data) {
  return axios.put(`module/edit/${id}`, data)
}

// --------------Support Setting-------------------

function getSupportSetting() {
  return axios.get(`support`)
}

function editSupportSettings(id, data) {
  return axios.put(`support/${id}`, data)
}

export default {
  getModuleListing,
  editModule,
  getSupportSetting,
  editSupportSettings
}
